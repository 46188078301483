export const bannerData = {
  title: {
    className: 'heading-1',
  },
  description: {
    text:
      'These Terms of Service (“TOS”) are legally binding on all Clients of The Design People, Inc., <br />including all of its divisions and subsidiaries (collectively “TDP”).',
    className: 'subtitle-5',
  },
}

export const items = [
  {
    title: 'Acceptance of Terms; Binding Contract',
    description: [
      'The TOS constitute a binding agreement between TDP and you (“Client”). The TOS apply to all services and products, and related support and consulting services and products (each a “Service” and collectively “Services”), made available by TDP to Client pursuant to the specifications of a Client agreement with TDP (“Client Agreement”), or a Client purchase order submitted by Client in connection with this Agreement (“Purchase Order”) for the term specified in such Purchase Order (“Initial Term”), and all renewals and/or extensions as set forth in this Agreement (collectively, the “Term”). TDP’s acceptance of any Purchase Order is expressly conditioned upon Client’s assent to this Agreement. By accepting the terms of this Agreement, electronically or in writing, or by otherwise using the Service or the Services, including, but not limited to, the submission of materials, information and/or content to TDP for TDP’s performance or rending of Services, or making payment and/or authorizing payment to TDP, Client agrees to be bound by the TOS set forth in this Agreement. Client also agrees that Client’s electronic acceptance of this Agreement shall have the same force and effect as if Client had agreed to the TOS set forth in this Agreement in writing. No terms or conditions set forth in Client’s Purchase Order, to which notice of objection is hereby given, or in any further correspondence between Client and TDP, shall alter or supplement this Agreement unless both TDP and Client have expressly agreed in writing to modify this Agreement. Neither TDP’s commencement of performance nor completion of Services shall be deemed or construed as acceptance of Client’s additional or different terms and conditions. TDP reserves the right to reject any Purchase Order or to cancel any Purchase Order previously accepted if TDP determines that Client is in breach of any term or condition set forth in this Agreement.',
      'TDP provides its services to Client subject to this Agreement, which may be updated from time to time without notice. Client may review the most current version of the TOS at <a href="https://thedesignpeople.com/terms-of-service" target="_blank">https://thedesignpeople.com/terms-of-service</a>. By using TDP’s Service or Services, Client agrees to (and hereby signs) the most current version of the TOS. If Client does not expressly reject the TOS at the point of sale, Client agrees to (and hereby signs) the TOS and TDP is instructed to commence work on Client’s website as if Client had expressly accepted the TOS. If Client expressly rejects the TOS set forth in this Agreement at the point of sale, TDP has no obligation to enter into a Client Agreement or Purchase Order, and may refuse to enter into a Client Agreement or Purchase Order.',
    ],
  },
  {
    title: 'Description of Services',
    description: [
      'TDP designs and hosts websites, provides Internet-based marketing services, and provides other website-related services, including, but not limited to, support and modification of websites, e-commerce, flash, web-traffic reporting, database development, easy interface for updating the website, IDX, email accounts and additional website-related services. Client understands that TDP’s Services may include certain communications from TDP such as advertisements, notices, service announcements and newsletters. Client is responsible for obtaining access to TDP’s Services that may involve third party fees (including but not limited to, ISP, merchant accounts and gateways). Client also is responsible for all equipment and software necessary to access TDP’s Services.',
    ],
  },
  {
    title: 'Electronic Delivery Policy',
    description: [
      'TDP is a website-related business and communicates with its Clients electronically. When a Client accepts the TOS, Client consents to receive electronically any notices, progress reports, agreements, disclosures, or other communications (“Notices”) from TDP. Client agrees that TDP may send Notices in any of the following ways: (1) to the email address provided by Client to TDP at the time of sale; or (2) to the new email address account Client establishes through TDP. Client agrees to check the designated email addresses regularly for Notices. All Notices from TDP are effective at the time they are sent by TDP, regardless of whether the Notice is read or received by Client.',
    ],
  },
  {
    title: 'Privacy Policy',
    description: [
      'Personal data and certain other information about the Client are subject to TDP’s Privacy Policy. For more information see the privacy policy at <a href="https://thedesignpeople.com/privacy-policy" target="_blank">https://thedesignpeople.com/privacy-policy</a>. By using TDP’s Services Client also agrees to the most current version of TDP’s Privacy Policy.',
    ],
  },
  {
    title: 'Confidential Information',
    description: [
      'Client acknowledges and agrees that any information or ideas submitted to TDP by any means may be used by TDP without compensation or liability to Client for any purpose whatsoever, including but not limited to, enhancing its Services, developing websites, databases, e-commerce and developing, manufacturing and marketing other products. This provision does not apply to Client content or to personal information that is subject to TDP’s Privacy Policy.',
      'Client hereby gives permission to TDP to use samples or links to Client’s website designed or marketed by TDP for marketing and advertising purposes, including but not limited to, use in TDP’s online portfolio and social media websites.',
    ],
  },
  {
    title: 'Call Monitoring and Recording Privacy Statement',
    description: [
      'As part of TDP’s commitment to providing its Clients with the best possible service, TDP may monitor and record telephone calls to and from TDP. TDP also may archive recorded voice mail messages. TDP records telephone calls for training purposes, to improve customer service, and to ensure an accurate record of Client calls, which may be needed to support transactions that take place over the telephone. TDP undertakes such actions in order to enable it to identify how to better serve its Clients.',
    ],
  },
  {
    title:
      '<span class="id-placeholder" id="unacceptable-practices"></span>Unacceptable Practices',
    description: [
      'As TDP strives to offer the very best Services, there are certain guidelines and policies that must govern TDP’s efforts and relationships with its Clients. Practices that are in violation of these guidelines and policies are forbidden and will result in the immediate termination of TDP’s Services. Client acknowledges and agrees that TDP has sole discretion over decisions regarding compliance with these guidelines and policies. Unacceptable practices include, but are not limited to:',
      {
        hasCheck: true,
        subItems: [
          'Adult or pornographic material including, but not limited to, sexually explicit or suggestive material',
          'Sexually oriented products or material',
          'Nudity, including airbrushing (exceptions granted on a case-by-case basis if for medical or artistic purposes)',
          'Offensive or otherwise distasteful material',
          'Bulk emailing tools',
          'Distribution of internet viruses or other harmful or destructive activities',
          'Gambling, gaming, lotteries, and similar activities',
          'Harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, racial, chauvinistic, ethnically offensive or otherwise objectionable content or language',
          'Defamatory, hateful or revenge content or language.',
          'Content or language that is harmful to minors in any way',
          'Illegal activities such as Ponzi schemes, fraudulent charging of credit cards, copyright violations, plagiarism, software piracy, and all unauthorized use of materials or content that infringes on third parties’ intellectual properties',
          'Intentional or unintentional violations of any applicable local, state, national or international law.',
          'Reselling email accounts or hosting accounts to third parties',
          'Reselling of any TDP services including, but not limited to, design services and updates to third parties without a written re-seller agreement.',
          'Spamming and all other forms of unsolicited messages including, but not limited to, spam, pyramid schemes, chain letters, and junk email',
          'Links to other sites that are in violation of TDP’s policies and guidelines',
          'Other activities, whether lawful or unlawful, that TDP deems to be in poor taste or that reflect adversely on TDP or TDP’s other clients',
          'TDP reserves the right to refuse to design or host an account at its sole discretion at anytime.',
        ],
      },
      'As a TDP Client, you agree to conduct your business in a legal and professional manner. As a TDP Client, you acknowledge and agree that all information, data, text, software, music, sound, photographs, video, messages and other material (Content) on Client’s website is the sole responsibility of Client. Client is fully responsible for all website content and agrees to hold TDP harmless in the event of third parties’ legal issues brought against Client for Client’s business practices. TDP retains the right to terminate any accounts that are in violation of the letter or spirit of this TOS. At its sole discretion and at any time, TDP may discontinue providing Services, or any part thereof, with or without notice. If an account is terminated by TDP for a TOS violation, Client is not eligible for a full refund and any refund is subject to the Termination/Cancellation of Services Policy. <a class="scroll-smooth" href="#termination">(See Termination/Cancellation of Services)</a>',
      'As a TDP Client you may have access to editing tools for your website. Client may edit, add, or delete content to or from the website at anytime. Accordingly, TDP may or may not pre-screen content. TDP shall have the right, but not the obligation, to pre-screen and refuse or remove any content at its sole discretion. Client agrees that Client bears all risks associated with the use of all content, whether edited or written by TDP, including any reliance upon accuracy, usefulness or completeness.',
      'Client acknowledges that TDP may access, preserve, and disclose Client’s account information and content if required to do so by law or in a good-faith belief that such access, preservation, or disclosure is reasonably necessary to comply with legal processes, enforce the TOS, provide customer service or protect the rights, property, or safety of TDP and the public.',
    ],
  },
  {
    title: 'Acceptable Use Policy',
    description: [
      'This AUP is incorporated by reference in your hosting services agreement with The Design People.',
      'Your services may be suspended or terminated for violation of this Acceptable Use Policy (“AUP”) in accordance with the TOS.',
      'Inquiries regarding the AUP should be directed to <a href="mailto:aup@thedesignpeople.com">aup@thedesignpeople.com</a>.',
      '<strong>Abuse</strong><br />Client may not use TDP’s Mail Service or services to engage in, foster, or promote illegal, abusive, or irresponsible behavior, including:',
      {
        hasCheck: true,
        subItems: [
          'Unauthorized access to or use of data, systems or networks, including any attempt to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without express authorization of the owner of the system or network;',
          'Monitoring data or traffic on any network or system without the express authorization of the owner of the system or network;',
          'Interference with service to any user of the TDP or other network including, without limitation, mail bombing, flooding, deliberate attempts to overload a system and broadcast attacks;',
          'Use of an Internet account or computer without the owner’s authorization;',
          'Collecting or using email addresses, screen names or other identifiers without the consent of the person identified (including, without limitation, phishing, Internet scamming, password robbery, spidering, and harvesting);',
          'Collecting or using information without the consent of the owner of the information;',
          'Use of any false, misleading, or deceptive TCP-IP packet header information in an email or a newsgroup posting;',
          'Use of the service to distribute software that covertly gathers information about a user or covertly transmits information about the user;',
          'Use of the service for distribution of advertisement delivery software unless: (i) the user affirmatively consents to the download and installation of such software based on a clear and conspicuous notice of the nature of the software, and (ii) the software is easily removable by use of standard tools for such purpose included on major operating systems; (such as Microsoft’s “ad/remove” tool); or',
          'Any conduct that is likely to result in retaliation against the TDP network or website, or TDP’s employees, officers or other agents, including engaging in behavior that results in any server being the target of a denial of service attack (DoS).',
        ],
      },
      '<strong>Bulk Email</strong><br />Client may not use a TDP Mail Service to send bulk mail. Please see the applicable Product Terms and Conditions for those services.',
      '<strong>Mail Requirements</strong><br />Client shall comply with the CAN-SPAM Act of 2003 and other laws and regulations applicable to bulk or commercial email. In addition, Client’s bulk and commercial email must meet the following requirements:',
      {
        hasCheck: true,
        subItems: [
          'Client’s intended recipients have given their consent to receive email from you via some affirmative means, such as an opt-in procedure;',
          'Client’s procedures for seeking consent include reasonable means to ensure that the person giving consent is the owner of the email address for which consent is given;',
          'Client retains evidence of each recipient’s consent in a form that can be promptly produced on request, and you honor recipient’s and TDP’s requests to produce consent evidence within 72 hours of receipt of the request;',
          'Client has procedures in place that allow a recipient to revoke their consent – such as a link in the body of the email, or instructions to reply with the word “Remove” in the subject line; Client honors revocations of consent within 48 hours, and notifies recipients that the revocation of their consent will be implemented in 48 hours;',
          'Client must post an email address for complaints (such as <a href="mailto:abuse@yourdomain.com">abuse@yourdomain.com</a>) in a conspicuous place on any website associated with the email, you must register that address at abuse.net, and you must promptly respond to messages sent to that address;',
          'Client must have a Privacy Policy posted for each domain associated with the mailing;',
          'Client has the means to track anonymous complaints;',
          'Client may not obscure the source of an email in any manner, such as omitting, forging, or misrepresenting message headers or return addresses. Client’s emails must include the recipient’s email address in the body of the message or in the “TO” line of the email;',
          'The subject line of the email must clearly describe the subject matter contained in the email, and the message must include valid contact information; and',
          'Client must not attempt to send any message to an email address if 3 consecutive delivery rejections have occurred and the time between the third rejection and the first rejection is longer than fifteen days.',
        ],
      },
      'These policies apply to messages sent using TDP services, or to messages sent from any network by you or any person on your behalf that directly or indirectly refer the recipient to a site or an email address hosted via your TDP service. In addition, you may not use a third party email service that does not practice similar procedures for all its customers. These requirements apply to distribution lists prepared by third parties to the same extent as if the list were created by you.',
      'TDP may test and otherwise monitor your compliance with its requirements. <strong>TDP may block the transmission of email that violates these provisions</strong>. TDP may, at its discretion, require certain Clients to seek advance approval for bulk and commercial email, which approval will not be granted unless the Client can demonstrate that all of the requirements stated above will be met.',
      '<strong>Unsolicited Communications</strong><br />You may not use your service to send email or any other communications to a person who has indicated that they do not wish to receive it. If the communication is bulk mail, then you will not be in violation of this section if you comply with the 48 hour removal requirement described above.',
      '<strong>Vulnerability Testing</strong><br />You may not attempt to probe, scan, penetrate or test the vulnerability of a TDP system or network, or to breach TDP’s security or authentication measures, whether by passive or intrusive techniques, without TDP’s express written consent.',
      '<strong>Newsgroup, Chat Forums, Other Networks</strong><br />You must comply with the rules and conventions for postings to any bulletin board, chat group or other forum in which you participate, such as IRC and USENET groups including their rules for content and commercial postings. These groups usually prohibit the posting of off-topic commercial messages, or mass postings to multiple forums.',
      'You must comply with the rules of any other network you access or participate in using your TDP services.',
      '<strong>Offensive Content</strong><br />You may not publish, transmit or store on or via TDP’s network and equipment any content or links to any content that TDP reasonably believes:',
      {
        hasCheck: true,
        subItems: [
          'Constitutes, depicts, fosters, promotes or relates in any manner to child pornography, bestiality, or non-consensual sex acts;',
          'is excessively violent, incites violence, threatens violence, or contains harassing content or hate speech;',
          'is unfair or deceptive under the consumer protection laws of any jurisdiction, including chain letters and pyramid schemes;',
          'is defamatory or violates a person’s privacy;',
          'creates a risk to a person’s safety or health, creates a risk to public safety or health, compromises national security, or interferes with a investigation by law enforcement;',
          'improperly exposes trade secrets or other confidential or proprietary information of another person;',
          'is intended to assist others in defeating technical copyright protections;',
          'infringes on another person’s copyright, trade or service mark, patent, or other property right;',
          'promotes illegal drugs, violates export control laws, relates to illegal gambling, or illegal arms trafficking;',
          'is otherwise illegal or solicits conduct that is illegal under laws applicable to you or to TDP; or',
          'is otherwise malicious, fraudulent, or may result in retaliation against TDP by offended viewers or recipients, or is intended to harass or threaten',
          'Content “published or transmitted” via TDP’s network or equipment includes Web content, email, bulletin board postings, chat, tweets, and any other type of posting or transmission that relies on the Internet.',
        ],
      },
      '<strong>Copyrighted Material</strong><br />You may not use TDP’s network or services to download, publish, distribute, or otherwise copy or use in any manner any text, music, software, art, image, or other work protected by copyright law unless:',
      {
        hasCheck: true,
        subItems: [
          'you have been expressly authorized by the owner of the copyright for the work to copy the work in that manner; or',
          'you are otherwise permitted by established copyright law to copy the work in that manner.',
        ],
      },
      'It is TDP’s policy to terminate in appropriate circumstances the services of customers who are repeat infringers.',
      '<strong>Email Retention Policy</strong><br />The following rules apply to the retention of e-mails in e-mail account hosted on TDP Mail Servers.',
      {
        hasCheck: true,
        subItems: [
          'All email in your inbox or personal folders that is either unread or marked unread will be deleted after 90 days.',
          'All read email will be retained indefinitely as long as you do not exceed the maximum storage quota for your mailbox. You can determine the available storage space by logging on to TDP Webmail.',
          'All email in the trash folder will be deleted after 30 days.',
        ],
      },
      '<strong>Other</strong>',
      {
        hasCheck: true,
        subItems: [
          'You must have valid and current information on file with your domain name registrar for any domain hosted on the TDP network.',
          'You may only use IP addresses assigned to you by TDP in connection with your TDP services.',
          'You agree that if the TDP IP numbers assigned to your account are listed on an abuse database (like, for example, Spamhaus), you will be in violation of this AUP, and TDP may take reasonable action to protect its IP numbers, including suspension and/or termination of your service, regardless of whether the IP numbers were listed as a result of your actions.',
        ],
      },
      '<strong>SLA</strong><br />No credit will be available under a TDP service level agreement for interruptions of service resulting from violations of the AUP.',
    ],
  },
  {
    title: 'Intellectual Property Policy',
    description: [
      'TDP respects copyright laws and the intellectual property of others. TDP may terminate Services and/or accounts for copyright infringement.',
      'TDP will not use copyrighted or trademarked materials on any Client’s website without the express written consent of the copyright or trademark owner. It is Client’s responsibility to ensure that all content submitted to TDP is original content and free from third-party copyright or trademark protection, or to obtain permission to use copyrighted or trademarked materials from the copyright or trademark owner. Client assumes full liability for any infringement of any third-party’s copyright or trademark rights as a result of the use of protected materials on Client’s website, including, but not limited to, any infringement due to website content, website design or the look and feel of Client’s website.',
      '<a class="scroll-smooth" href="#unacceptable-practices">(See Unacceptable Practices)</a>',
      'Client content that is sent to TDP will remain the intellectual property of Client. TDP does not return original content to Client. Unless a request to return the original content to Client is made in writing upon submission of the content, the content will be destroyed. TDP will attempt to honor requests to return original content, however, TDP has no liability and does not guarantee the return of any content to Client.',
      'Domain names purchased by TDP and website designs, databases, stores, or programs created by TDP, are the property of TDP until Client has paid all fees including one full year of monthly hosting. <a class="scroll-smooth" href="#domain-names">(See Domain Names Purchase/Hosting Agreement)</a>',
      'The designs and database technology for all AgentPlus, AgentPro, OfficePro and Semi-Custom website products are proprietary. However, when you purchase a custom website from TDP pursuant to the terms of these TOS, you own the design and license the technology from Agent Image.',
    ],
  },
  {
    title: 'International Use',
    description: [
      'Recognizing the global nature of the Internet, Client agrees to comply with all local rules regarding online conduct and acceptable content. Specifically, Client agrees to comply with all laws regarding the transmission of technical data exported from the United States, or the country in which Client resides and/or transacts business.',
    ],
  },
  {
    title: 'Interstate Communications',
    description: [
      'Client acknowledges that by using TDP’s Services Client will be causing communications to be sent through TDP’s computer networks, which may be located throughout the United States. Due to the nature of electronic communications, even communications that seem to be intrastate can result in the transmission of interstate communications. Client acknowledges that use of TDP’s services results in interstate data transmissions.',
    ],
  },
  {
    title: 'Website Construction Procedure',
    description: [
      'Where applicable, with help and input from Client, TDP will prepare the appropriate design and work with the content provided by Client for development of the website. Client must submit content to the design department before site construction begins on the custom website. Client must submit content to TDP using the Design Meeting Packet (DMP). Client must electronically accept the TOS before access is granted to the DMP. After content is submitted by Client, the website is developed. Prior to the website being taken live, Client will receive a missing information notification if content is incomplete. Client will then have five (5) business days to submit missing or incomplete content. If complete content is not received, the website will be taken live “as-is”. If the website is taken live without all of the pages completed due to incomplete content, those pages may be banked and developed in the future using Client’s design time.',
      'In submitting content through the DMP, links to sample sites Client likes are for general information purposes only and assist TDP with the design of Client’s custom website. The functionality and detail of the sample sites will not be duplicated unless such functionality and detail are specifically included and itemized in TDP’s proposal. Direct duplications of sample sites will not be permitted unless the original sample site owner provides express, written consent.',
      'The design and content layout are completed by the designer and presented to Client for approval. After Client approves the website, the website will go live. TDP may review the text before the website goes live to correct any possible errors. TDP is not liable for the accuracy of information, typing or spelling errors in any of the content approved by Client and published on the website. Client will be notified by email that the website is live.',
      'Client understands, agrees, and acknowledges that TDP does not guarantee a time frame for completion of ANY website. A website cannot be completed without submission of complete content, design approvals, and participation from Client. If Client continues submitting additional content throughout the design process, the design time frame is increased. If Client does not submit complete content and TDP is not able to start or complete the custom website design, Client still is responsible for and agrees to pay all fees incurred, including, but not limited to, set-up, enhancement, and monthly hosting charges that begin accruing from date of sale. If Client’s website requires custom programming, functionality, flash, e-commerce, IDX, or the use of a database, the overall development time will be extended.',
      'Client is provided with a placeholder immediately after sale. Upon request, Client also will be provided with an optional “welcome website” shortly after the initial sale. The welcome website is a temporary website Client can modify and direct customers to while the website is being built. Client may choose not to have a welcome website.',
    ],
  },
  {
    title: 'Progress Reports',
    description: [
      'TDP shall contact or meet with Client on a mutually acceptable schedule to report all tasks completed, problems encountered, and recommended changes relating to the development and testing of the website. TDP shall inform Client promptly by telephone or e-mail upon discovery of any event or problem that may delay significantly the development of the design work.',
    ],
  },
  {
    title: 'Developers Guarantee for Program Use',
    description: [
      'TDP agrees to notify Client of any licensing and/or permissions required for art-generating/driving programs to be used.',
    ],
  },
  {
    title: 'Changes/Revisions',
    description: [
      'TDP agrees to provide any and all Services, including but not limited to, the design and construction of a website or marketing services, pursuant to the specifications contained in the original terms of sale. Any additions or changes to Services requested by Client that are outside of or beyond the scope of the original terms of sale will be billed to Client at TDP’s standard hourly rate ($150/hour), and Client agrees to pay all charges billed for such additions or changes. TDP is not obligated to complete any additions or changes to Services requested by Client that are outside of the scope of work contained in the original terms of sale. If TDP does not agree to the Client’s requests for additions or changes to Services, Client still is obligated to pay all fees incurred and due. With respect to the design and construction of a website, Client will be provided two (2) opportunities to alter the proposed web design after delivery of the concept designs by TDP to the Client for review, and two (2) opportunities to alter the proposed web program after all user requirements and functional requirements have been completed by TDP.',
    ],
  },
  {
    title: 'Testing and Acceptance Procedures',
    description: [
      'TDP will make a good faith effort to test all deliverables thoroughly and make all necessary corrections as a result of such testing prior to sending the deliverables to Client. Upon receipt of the deliverables, Client shall either: (1) accept the deliverables and make the required milestone payment set forth therein; or (2) provide TDP with written or e-mail notification of any corrections Client requires, and a suggested date for completion of required corrections. The suggested date for completion of required corrections shall be reasonable and mutually acceptable to both TDP and Client. TDP and Client shall each designate one representative as the designated person who will send, receive, and accept all deliverables and communications between TDP and Client. Neither TDP nor Client shall have any obligation to consider for approval, or respond to, communication, deliverables, or materials submitted by any person other than the designated person. Both TDP and Client each have the right to change its designated person upon two (2) days’ notice to the other.',
    ],
  },
  {
    title: 'Scope of Work',
    description: [
      'TDP will facilitate the creation of all necessary change procedures with Client, particularly: PSD to HTML, CSS, will ensure HTML works in latest browsers (Chrome, Safari, FireFox), will ensure HTML is compatible with the latest version of Edge, will ensure conversion works on mobile browsers (​iPhone/iPad​ [iOS 10+]​ and Android​ [4.x+]​.',
      '<strong>Agent Image X Package Inclusions</strong>',
      '<strong>Description:</strong> Agent Image X websites are high-quality real estate websites for agents with limited marketing budgets. Agent Image X websites are best partnered with an IDX package.',
      '<strong>This website package includes the following:</strong>',
      {
        hasCheck: false,
        subItems: [
          'Hosted WordPress website',
          '<span class="id-placeholder" id="full-responsive-design"></span>Fully responsive design',
          {
            hasCheck: false,
            subItems: [
              'Our websites follow a specific responsive framework that would automatically adjust to the broadest possible range of devices. This Bootstrap framework allows users to have an optimal experience whether they access the website from a desktop, tablet, or mobile device. When updates are implemented on the website, it would not entail any separate coding to make it work on mobile, and would instead automatically fit a set grid regardless of device. All websites are designed with this same mobile framework.',
            ],
          },
          'A pre-designed home page and inner page layouts',
          {
            hasCheck: false,
            subItems: [
              'Rearranging or modifying the homepage and inner page layouts is not possible.',
            ],
          },
          'The number of pages on the main navigation is fixed according to the chosen website theme. If an additional page is needed beyond the set theme, it will be added as a dropdown menu tab.',
          '<span class="id-placeholder" id="slideshow"></span>Slideshow',
          {
            hasCheck: false,
            subItems: [
              '3 to 5 photos or;',
              {
                hasCheck: false,
                subItems: [
                  'If no photos are supplied, we are able to provide a limited number of free photos from our existing image repository. This may be discussed in detail with the website’s assigned Project Manager.',
                  'Any complimentary photos supplied for the website project are strictly for Agent Image website use only.',
                ],
              },
              '<span class="id-placeholder" id="video"></span>Video:',
              {
                hasCheck: false,
                subItems: [
                  'The video file must be supplied by the client in consideration of the guidelines stated below:',
                  'Video Guidelines:',
                  {
                    hasCheck: false,
                    subItems: [
                      'Video Length: 30 seconds is the prescribed total video length in line with website best practices and managing website loading time.',
                      'Aspect Ratio: Any aspect ratio is acceptable, but to fill the display area the video may be center-cropped depending on the user’s screen resolution. The subject of the video should be at the center and text on the video should be avoided, if possible, to prevent unwanted display issues.',
                      'File Format: Any common file format is acceptable (preferably mp4, avi, wmv).',
                      'File Size: The total file size of all submitted video content must not exceed 50MB.',
                      'Video Quality: It is preferred that video content is already compressed upon submission. If video content submitted is not yet compressed, please take note that the quality of the video may suffer and deviate from the original copy. File compression is a necessary step to conserve loading time. Note that a higher bit-rate may also be observed when video content has a bright color display.',
                      'Mobile Device Limitations: Auto-playing video content on mobile devices is discouraged as it is not standard practice. The video content may be removed or replaced when the website is viewed on a mobile device.',
                      'Audio Limitations: Video with included audio is only supported for a single video clip. It is not supported for multiple video clips to avoid staggering audio playback. If audio is to be present on a video file, 1 continuous audio track will be accepted.',
                      'Continuous Play vs. Video Stitching: It is preferred that separate video clips are already stitched into 1 continuous video loop, ready-to-play, without the need for further editing. This ensures that video content is displayed exactly set to preference. If this cannot be avoided, no greater than 6 total video clips shall be provided by the client for stitching; in which case, video clips will be played one after another. The extent of video editing will be comprised of cut-to-cut video cropping only, with direction from the client. No additional custom graphic effects will be supplied.',
                    ],
                  },
                ],
              },
            ],
          },
          'Quick Search Form For Properties',
          {
            hasCheck: false,
            subItems: [
              'A quick search form can only be provided in tandem with an IDX system from iHomefinder or IDX Broker only.',
            ],
          },
          'Featured Communities',
          {
            hasCheck: false,
            subItems: [
              'The number of communities displayed on the homepage is limited to what is available on the chosen website theme.',
              'Additional communities may be added as sub-pages under the main Communities page.',
            ],
          },
          'Lead Capture Forms and Calls-To-Action',
          {
            hasCheck: false,
            subItems: [
              'There is a set number of call-to-action buttons per website theme.',
              'Auto-responders for lead capture forms may be set to personalize messages.',
            ],
          },
          'An SEO-ready dashboard',
          'Links to social media accounts',
          'Website Template Colors',
          {
            hasCheck: false,
            subItems: [
              'This is limited to 2 colors only: 1 primary color and 1 accent color.',
              'Adjustments may be made to the proposed colors to achieve the best results for the website’s digital format, especially with regard to accessibility. This may be discussed in detail with the website’s assigned Project Manager.',
            ],
          },
          'Font Colors',
          {
            hasCheck: false,
            subItems: [
              'This is limited to 2 colors only: 1 color for headings and 1 color for body text.',
              'Adjustments may be made to the proposed colors to achieve the best results for the website’s digital format, especially with regard to accessibility. This may be discussed in detail with the website’s assigned Project Manager.',
            ],
          },
          'Clients are entitled to a one-time free theme change after the initial build.',
          'Client will own the website after one (1) calendar year from the date of purchase.',
          '<strong>Website Inner Pages include:</strong>',
          {
            hasCheck: false,
            subItems: [
              'An About page with editable stock content using a pre-designed layout.',
              '3 property showcase pages with property details using pre-designed layouts:',
              {
                hasCheck: false,
                subItems: [
                  'The appearance of listing pages is determined by the data source of the listings. The data source of listings may be from manually uploaded listings on the website, or through IDX providers such as iHomefinder and IDX Broker',
                  {
                    hasCheck: false,
                    subItems: [
                      'Active Listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'Manually uploaded listings, limited to 5 to 10 properties only',
                          {
                            hasCheck: false,
                            subItems: [
                              'If a client wishes to display more than 10 properties, they will be given instruction on how to upload additional properties to the website.',
                            ],
                          },
                          'IDX provider as a data source for listings',
                          {
                            hasCheck: false,
                            subItems: [
                              'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                            ],
                          },
                        ],
                      },
                      'Sold Listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'Manually uploaded listings, limited to 5 to 10 properties only',
                          {
                            hasCheck: false,
                            subItems: [
                              'If a client wishes to display more than 10 properties, they will be given instruction on how to upload additional properties to the website.',
                            ],
                          },
                          'IDX provider as a data source for listings',
                          {
                            hasCheck: false,
                            subItems: [
                              'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                            ],
                          },
                        ],
                      },
                      'Search page (possible only with the purchase of an IDX package)',
                      'Property details page will display the following content using a pre-designed layout:',
                      {
                        hasCheck: false,
                        subItems: [
                          'Photo gallery',
                          'Property description',
                          'Property information',
                          'Location map',
                          'Mortgage calculator',
                          'Lead capture forms',
                          'Social media sharing',
                        ],
                      },
                    ],
                  },
                ],
              },
              'Community pages using a pre-designed layout, limited to 5 to 7 communities only',
              {
                hasCheck: false,
                subItems: [
                  'Each page contains a section to add a community description, one (1) featured image, and a section to display active properties with an IDX provider as a data source for listings.',
                  'If a client wishes to display more than 7 communities, they will be given instruction on how to upload additional communities to the website.',
                ],
              },
              'Testimonials page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Manually uploaded, limited to 5 to 10 testimonials only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 10 testimonials, they will be given instruction on how to upload additional testimonials to the website.',
                      'Integration of third-party plugins and data sources, such as those from Zillow and Google reviews, is not allowed.',
                    ],
                  },
                ],
              },
              'Social media page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Client must register and validate their social media account credentials for respective social media channels in order to successfully integrate social media post content via the AIOS Social Media plugin. This may be discussed in detail with the website’s assigned Project Manager.',
                ],
              },
              'Contact page',
              {
                hasCheck: false,
                subItems: ['A pre-designed page with a lead capture form'],
              },
              'Buyers page with editable stock content using a pre-designed layout',
              'Sellers page with editable stock content using a pre-designed layout',
              'Real estate news feed from Inman News',
              {
                hasCheck: false,
                subItems: [
                  'May be replaced with a different RSS feed, if preferred',
                  'May be replaced with manually uploaded blog articles provided by the client, limited to 5 to 10 posts only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 10 posts, they will be given instruction on how to upload additional posts to the website.',
                    ],
                  },
                ],
              },
            ],
          },
          'Client may request their Project Manager to add 3 additional pages to the website free of charge.',
          {
            hasCheck: false,
            subItems: [
              'These 3 pages will use the standard inner page layout. The header and footer will be patterned after the homepage design. The content section of these pages will allow for the addition of text and photos or display properties via IDX provider as a data source for listings.',
              'If a client wishes to display more than 3 additional pages, they will be given instruction on how to upload additional pages to the website.',
            ],
          },
        ],
      },
      '<strong>Available add-ons that may be purchased in addition to the Agent Image X website package:</strong><br />Please note that the purchase of any of these add-ons while the website build is in progress will extend the website project timeline depending on the scope of work. If a website add-on is availed, a change order form will need to be completed.',
      {
        hasCheck: false,
        subItems: [
          '<span class="id-placeholder" id="crm-integration"></span><strong>CRM Integration</strong>',
          {
            hasCheck: false,
            subItems: [
              'The coverage of CRM integration will be dependent on the CRM vendor of choice. Some CRM platforms will only allow email parsing, and therefore can only be partially, not fully, integrated.',
              'The amount of additional hours of custom labor for CRM integration will depend on the CRM platform that is chosen. This may be discussed in detail with the website’s assigned Project Manager.',
            ],
          },
          '<span class="id-placeholder" id="custom-logo-design"></span><strong>Custom Logo Design</strong>',
          {
            hasCheck: false,
            subItems: [
              'The creation of a custom-designed logo costs $399.00.',
              'This may be discussed in detail with the website’s assigned Project Manager.',
            ],
          },
        ],
      },
      '<span class="hr"></span>',
      '<strong>Agent Pro Package Inclusions</strong>',
      '<strong>Description:</strong> Agent Pro real estate websites are designed to capture leads through exclusive and beautiful designs, as well as strategically placed calls-to-action and contact forms.',
      'This website package includes the following:',
      {
        hasCheck: false,
        subItems: [
          'Hosted WordPress website',
          'Fully responsive design',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#full-responsive-design">(See Fully responsive design)</a>',
            ],
          },
          'A beautiful pre-designed home page and inner page layouts',
          {
            hasCheck: false,
            subItems: [
              'Rearranging or modifying the homepage and inner page layouts is not possible.',
            ],
          },
          'The number of pages on the main navigation is fixed according to the chosen website theme. If an additional page is needed beyond the set theme, it will be added as a dropdown menu tab.',
          'Slideshow',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#slideshow">(See Slideshow)</a>',
              '<a class="scroll-smooth" href="#video">(See Video)</a>',
            ],
          },
          'Quick Search Form For Properties',
          {
            hasCheck: false,
            subItems: [
              'A quick search form can only be provided in tandem with an IDX system from iHomefinder or IDX Broker only.',
            ],
          },
          'Featured Communities',
          {
            hasCheck: false,
            subItems: [
              'The number of communities displayed on the homepage is limited to what is available on the chosen website theme.',
              'Additional communities may be added as sub-pages under the main Communities page.',
            ],
          },
          'Lead Capture Forms and Calls-To-Action',
          {
            hasCheck: false,
            subItems: [
              'There is a set number of call-to-action buttons per website theme.',
              'Auto-responders for lead capture forms may be set to personalize messages.',
            ],
          },
          'An SEO-ready dashboard',
          'Links to social media accounts',
          'Website Template Colors',
          {
            hasCheck: false,
            subItems: [
              'This is limited to 2 colors only: 1 primary color and 1 accent color.',
              'Adjustments may be made to the proposed colors to achieve the best results for the website’s digital format, especially with regard to accessibility. This may be discussed in detail with the website’s assigned Project Manager.',
            ],
          },
          'Font Colors',
          {
            hasCheck: false,
            subItems: [
              'This is limited to 2 colors only: 1 color for headings and 1 color for body text.',
              'Adjustments may be made to the proposed colors to achieve the best results for the website’s digital format, especially with regard to accessibility. This may be discussed in detail with the website’s assigned Project Manager.',
            ],
          },
          'Clients are entitled to a one-time free theme change after the initial build',
          'Client will own the website after one (1) calendar year from the date of purchase.',
          '<strong>Website Inner Pages include:</strong>',
          {
            hasCheck: false,
            subItems: [
              'An About page with editable stock content using a pre-designed layout.',
              'A Profile or Team page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Manually uploaded profiles, limited to 15 to 20 profiles only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 20 profiles, they will be given instruction on how to upload additional profiles to the website.',
                    ],
                  },
                ],
              },
              '3 property showcase pages with property details using pre-designed layouts:',
              {
                hasCheck: false,
                subItems: [
                  'Active Listings',
                  {
                    hasCheck: false,
                    subItems: [
                      'Manually uploaded listings, limited to 15 to 20 properties only',
                      {
                        hasCheck: false,
                        subItems: [
                          'If a client wishes to display more than 20 properties, they will be given instruction on how to upload additional properties to the website.',
                        ],
                      },
                      'IDX provider as a data source for listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                        ],
                      },
                    ],
                  },
                  'Sold Listings',
                  {
                    hasCheck: false,
                    subItems: [
                      'Manually uploaded listings, limited to 15 to 20 properties only',
                      {
                        hasCheck: false,
                        subItems: [
                          'If a client wishes to display more than 20 properties, they will be given instruction on how to upload additional properties to the website.',
                        ],
                      },
                      'IDX provider as a data source for listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                        ],
                      },
                    ],
                  },
                  'Search page (possible only with the purchase of an IDX package)',
                  'Property details page will display the following content using a pre-designed layout:',
                  {
                    hasCheck: false,
                    subItems: [
                      'Photo gallery',
                      'Property description',
                      'Property information',
                      'Location map',
                      'Mortgage calculator',
                      'Lead capture forms',
                      'Social media sharing',
                    ],
                  },
                ],
              },
              'Community pages using a pre-designed layout, limited to 5 to 7 communities only',
              'Testimonials page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Manually uploaded, limited to 15 to 20 testimonials only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 20 testimonials, they will be given instruction on how to upload additional testimonials to the website.',
                      'Integration of third-party plugins and data sources, such as those from Zillow and Google reviews, is not allowed.',
                      {
                        hasCheck: false,
                        subItems: [
                          'There may be a limit to the number of testimonials displayed on the website display, depending on the plugin chosen, which is beyond Agent Image’s control.',
                        ],
                      },
                    ],
                  },
                ],
              },
              'Social media page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Client must register and validate their social media account credentials for respective social media channels in order to successfully integrate social media post content via the AIOS Social Media plugin. This may be discussed in detail with the website’s assigned Project Manager.',
                ],
              },
              'Contact page',
              {
                hasCheck: false,
                subItems: ['A pre-designed page with a lead capture form'],
              },
              'Buyers page with editable stock content using a pre-designed layout',
              'Sellers page with editable stock content using a pre-designed layout',
              'Real estate news feed from Inman News',
              {
                hasCheck: false,
                subItems: [
                  'May be replaced with a different RSS feed, if preferred',
                  'May be replaced with manually uploaded blog articles provided by the client, limited to 15 to 20 posts only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 20 posts, they will be given instruction on how to upload additional posts to the website.',
                    ],
                  },
                ],
              },
            ],
          },
          'Client may request their Project Manager to add 5 additional pages to the website free of charge.',
          {
            hasCheck: false,
            subItems: [
              'These 5 pages will use the standard inner page layout. The header and footer will be patterned after the homepage design. The content section of these pages will allow for the addition of text and photos or display properties via IDX provider as a data source for listings.',
              'If a client wishes to display more than 5 additional pages, they will be given instruction on how to upload additional pages to the website.',
            ],
          },
        ],
      },
      '<strong>Available add-ons that may be purchased in addition to the Agent Pro website package:</strong>',
      'Please note that the purchase of any of these add-ons while the website build is in progress will extend the website project timeline depending on the scope of work. If a website add-on is availed, a change order form will need to be completed.',
      {
        hasCheck: false,
        subItems: [
          '<strong>CRM Integration</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#crm-integration">(See CRM Integration)</a>',
            ],
          },
          '<strong>Custom Logo Design</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#custom-logo-design">(See Custom Logo Design)</a>',
            ],
          },
          '<span class="id-placeholder" id="custom-map"></span><strong>Custom Map</strong>',
          {
            hasCheck: false,
            subItems: [
              'The creation of a custom-designed map costs $999.00, inclusive of 10 clickable areas.',
              'There will be a $50.00 charge per additional area beyond the first 10 areas.',
            ],
          },
          '<strong>Custom Property Layout</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
          '<strong>Custom Page Layout</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
        ],
      },
      '<span class="hr"></span>',
      '<strong>Semi-Custom Package Inclusions</strong>',
      '<strong>Description:</strong> The Semi-Custom real estate website package is based on the same inclusions of Agent Pro website design templates, but includes a custom-designed homepage and inner page framework.',
      'The Semi-Custom real estate website package is designed to capture leads through a beautiful custom Agent Image design, as well as strategically placed calls-to-action and contact forms.',
      '<strong>This website package includes the following:</strong>',
      {
        hasCheck: false,
        subItems: [
          'Hosted WordPress website',
          'Fully responsive design',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#full-responsive-design">(See Fully responsive design)</a>',
            ],
          },
          'A custom designed homepage and matching inner page designs',
          {
            hasCheck: false,
            subItems: [
              'All inner page layouts are pre-designed. The header and footer layout will be created to match the homepage design.',
              'Design Revisions:',
              {
                hasCheck: false,
                subItems: [
                  'Each custom design includes up to three (3) major revisions only.',
                  'A major revision is defined by any design changes that take more than one (1) hour of design work.',
                  'Design requests that exceed three (3) major revisions will result in additional fees charged to the client.',
                ],
              },
              'Homepage Redesign:',
              {
                hasCheck: false,
                subItems: [
                  'Each custom design project includes the option of two (2) homepage redesigns during the design phase if a client chooses to pursue a different design direction.',
                ],
              },
              'The homepage design must be finalized and approved by the client before proceeding to HTML conversion.',
              {
                hasCheck: false,
                subItems: [
                  'Once the homepage design is approved and converted to HTML, the client may no longer change the layout or design elements therein. Only edits and replacements to the existing and converted photos and text will be permitted.',
                  'Changes to an already approved and converted homepage design will result in additional fees charged to the client and an extension to the website project timeline.',
                ],
              },
            ],
          },
          'The number of pages on the main navigation is dependent on the approved homepage design. A maximum of 7-8 main menu navigation tabs are prescribed for optimal user experience.',
          'Slideshow',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#slideshow">(See Slideshow)</a>',
              '<a class="scroll-smooth" href="#video">(See Video)</a>',
            ],
          },
          'Quick Search Form For Properties',
          {
            hasCheck: false,
            subItems: [
              'A quick search form can only be provided in tandem with an IDX system from iHomefinder or IDX Broker only.',
              'The use of a third-party IDX provider that has a WordPress plugin may be subject to additional fees charged to the client, depending on the chosen IDX provider.',
            ],
          },
          'Featured Communities on the homepage are dependent on the approved homepage design.',
          'Lead capture forms and calls-to-action throughout the website',
          {
            hasCheck: false,
            subItems: [
              'The lead capture forms will depend on the approved homepage design. This may be discussed in detail with the website’s assigned Art Director or Digital Strategist.',
              'Auto-responders for lead capture forms may be set to personalize messages.',
            ],
          },
          'An SEO-ready dashboard',
          'Links to social media accounts',
          'Client will own the website after one (1) calendar year from the date of purchase.',
          '<strong>Website Inner Pages include:</strong>',
          {
            hasCheck: false,
            subItems: [
              'An About page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Editable stock content can be provided upon request, which may be edited by the client at will.',
                ],
              },
              'A Profile or Team page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Manually uploaded profiles, limited to 15 to 20 profiles only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 20 profiles, they will be given instruction on how to upload additional profiles to the website.',
                    ],
                  },
                ],
              },
              '3 property showcase pages with property details using pre-designed layouts:',
              {
                hasCheck: false,
                subItems: [
                  'Active Listings',
                  {
                    hasCheck: false,
                    subItems: [
                      'Manually uploaded listings, limited to 15 to 20 properties only',
                      {
                        hasCheck: false,
                        subItems: [
                          'If a client wishes to display more than 20 properties, they will be given instruction on how to upload additional properties to the website.',
                        ],
                      },
                      'IDX provider as a data source for listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                        ],
                      },
                    ],
                  },
                  'Sold Listings',
                  {
                    hasCheck: false,
                    subItems: [
                      'Manually uploaded listings, limited to 15 to 20 properties only',
                      {
                        hasCheck: false,
                        subItems: [
                          'If a client wishes to display more than 20 properties, they will be given instruction on how to upload additional properties to the website.',
                        ],
                      },
                      'IDX provider as a data source for listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                        ],
                      },
                    ],
                  },
                  'Search page (possible only with the purchase of an IDX package)',
                  'Property details page will display the following content using a pre-designed layout:',
                  {
                    hasCheck: false,
                    subItems: [
                      'Photo gallery',
                      'Property description',
                      'Property information',
                      'Location map',
                      'Mortgage calculator',
                      'Lead capture forms',
                      'Social media sharing',
                    ],
                  },
                ],
              },
              'Community pages using a pre-designed layout, limited to 15 to 20 communities only',
              {
                hasCheck: false,
                subItems: [
                  'Each page contains a section to add a community description, one (1) featured image, and a section to display active properties with an IDX provider as a data source for listings.',
                  'If a client wishes to display more than 20 communities, they will be given instruction on how to upload additional communities to the website.',
                ],
              },
              'Testimonials page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Manually uploaded, limited to 15 to 20 testimonials only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 20 testimonials, they will be given instruction on how to upload additional testimonials to the website.',
                      'Integration of third-party plugins and data sources, such as those from Zillow and Google reviews, is not allowed.',
                      {
                        hasCheck: false,
                        subItems: [
                          'There may be a limit to the number of testimonials displayed on the website display, depending on the plugin chosen, which is beyond Agent Image’s control.',
                        ],
                      },
                    ],
                  },
                ],
              },
              'Social media page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Client must register and validate their social media account credentials for respective social media channels in order to successfully integrate social media post content via the AIOS Social Media plugin. This may be discussed in detail with the website’s assigned Project Manager.',
                ],
              },
              'Contact page',
              {
                hasCheck: false,
                subItems: ['A pre-designed page with a lead capture form'],
              },
              'Buyers page with editable stock content using a pre-designed layout',
              'Sellers page with editable stock content using a pre-designed layout',
              'Real estate news feed from Inman News',
              {
                hasCheck: false,
                subItems: [
                  'May be replaced with a different RSS feed, if preferred',
                  'May be replaced with manually uploaded blog articles provided by the client, limited to 15 to 20 posts only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 20 posts, they will be given instruction on how to upload additional posts to the website.',
                    ],
                  },
                ],
              },
            ],
          },
          'Client may request their Project Manager to add 10 additional pages to the website free of charge.',
          {
            hasCheck: false,
            subItems: [
              'These 10 pages will use the standard inner page layout. The header and footer will be patterned after the homepage design. The content section of these pages will allow for the addition of text and photos or display properties via IDX provider as a data source for listings.',
              'If a client wishes to display more than 10 additional pages, they will be given instruction on how to upload additional pages to the website.',
            ],
          },
        ],
      },
      '<strong>Available add-ons that may be purchased in addition to the Semi-Custom website package:</strong>',
      'Please note that the purchase of any of these add-ons while the website build is in progress will extend the website project timeline depending on the scope of work. If a website add-on is availed, a change order form will need to be completed.',
      {
        hasCheck: false,
        subItems: [
          '<strong>CRM Integration</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#crm-integration">(See CRM Integration)</a>',
            ],
          },
          '<strong>Custom Logo Design</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#custom-logo-design">(See Custom Logo Design)</a>',
            ],
          },
          '<strong>Custom Map</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#custom-map">(See Custom Map)</a>',
            ],
          },
          '<strong>Custom Property Layout</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
          '<strong>Custom Page Layout</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
          '<strong>Custom Form Layout and Functionality</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
          '<strong>Integration of any Third-Party Application/Plugin/<strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
        ],
      },
      '<span class="hr"></span>',
      '<strong>Imagine Studio Inclusions</strong>',
      '<strong>Description:</strong> Imagine Studio real estate websites are defined by their craftsmanship, attention to detail, module layout options, and all-inclusive features. This website includes a custom-designed homepage and matching inner page framework, as well as three (3) custom-designed inner page layouts for any three website sections of choice.',
      'The Imagine Studio website package is designed to capture leads through a beautiful custom Agent Image design, as well as strategically placed calls-to-action and contact forms.',
      '<strong>This website package includes the following:</strong>',
      {
        hasCheck: false,
        subItems: [
          'Hosted WordPress website',
          'Fully responsive design',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#full-responsive-design">(See Fully responsive design)</a>',
            ],
          },
          'A custom homepage and matching inner page designs',
          {
            hasCheck: false,
            subItems: [
              'All inner page layouts are pre-designed, with the exception of three (3) customizable inner pages that the client must specify. The header and footer layout will be created to match the homepage design.',
              'Design Revisions:',
              {
                hasCheck: false,
                subItems: [
                  'Each custom design includes up to three (3) major revisions only.',
                  'A major revision is defined by any design changes that take more than one (1) hour of design work.',
                  'Design requests that exceed three (3) major revisions will result in additional fees charged to the client.',
                ],
              },
              'Homepage Redesign:',
              {
                hasCheck: false,
                subItems: [
                  'Each custom design project includes the option of two (2) homepage redesigns during the design phase if a client chooses to pursue a different design direction.',
                ],
              },
              'The homepage design must be finalized and approved by the client before proceeding to HTML conversion.',
              {
                hasCheck: false,
                subItems: [
                  'Once the homepage design is approved and converted to HTML, the client may no longer change the layout or design elements therein. Only edits and replacements to the existing and converted photos and text will be permitted.',
                  'Changes to an already approved and converted homepage design will result in additional fees charged to the client and an extension to the website project timeline.',
                ],
              },
            ],
          },
          'The number of pages on the main navigation is dependent on the approved homepage design. A maximum of 7-8 main menu navigation tabs are prescribed for optimal user experience.',
          'Slideshow',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#slideshow">(See Slideshow)</a>',
              '<a class="scroll-smooth" href="#video">(See Video)</a>',
            ],
          },
          'Quick Search Form For Properties',
          {
            hasCheck: false,
            subItems: [
              'A quick search form can only be provided in tandem with an IDX system from iHomefinder or IDX Broker only.',
              'The use of a third-party IDX provider that has a WordPress plugin may be subject to additional fees charged to the client, depending on the chosen IDX provider.',
            ],
          },
          'Featured Communities on the homepage are dependent on the approved homepage design.',
          'Lead capture forms and calls-to-action throughout the website',
          {
            hasCheck: false,
            subItems: [
              'The lead capture forms will depend on the approved homepage design. This may be discussed in detail with the website’s assigned Art Director or Digital Strategist.',
              'Auto-responders for lead capture forms may be set to personalize messages.',
            ],
          },
          'An SEO-ready dashboard',
          'Links to social media accounts',
          'Client will own the website after one (1) calendar year from the date of purchase.',
          '<strong>Website Inner Pages include:</strong>',
          {
            hasCheck: false,
            subItems: [
              'An About page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Editable stock content can be provided upon request, which may be edited by the client at will.',
                ],
              },
              'A Profile or Team page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Manually uploaded profiles, limited to 30 to 40 profiles only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 40 profiles, they will be given instruction on how to upload additional profiles to the website.',
                    ],
                  },
                ],
              },
              '3 property showcase pages with property details using pre-designed layouts:',
              {
                hasCheck: false,
                subItems: [
                  'Active Listings',
                  {
                    hasCheck: false,
                    subItems: [
                      'Manually uploaded listings, limited to 30 to 40 properties only',
                      {
                        hasCheck: false,
                        subItems: [
                          'If a client wishes to display more than 40 properties, they will be given instruction on how to upload additional properties to the website.',
                        ],
                      },
                      'IDX provider as a data source for listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                        ],
                      },
                    ],
                  },
                  'Sold Listings',
                  {
                    hasCheck: false,
                    subItems: [
                      'Manually uploaded listings, limited to 30 to 40 properties only',
                      {
                        hasCheck: false,
                        subItems: [
                          'If a client wishes to display more than 40 properties, they will be given instruction on how to upload additional properties to the website.',
                        ],
                      },
                      'IDX provider as a data source for listings',
                      {
                        hasCheck: false,
                        subItems: [
                          'IDX accounts vary in pricing for different MLS Boards, number of account users, IDX packages, and a number of other factors.',
                        ],
                      },
                    ],
                  },
                  'Search page (possible only with the purchase of an IDX package)',
                  'Property details page will display the following content using a pre-designed layout:',
                  {
                    hasCheck: false,
                    subItems: [
                      'Photo gallery',
                      'Property description',
                      'Property information',
                      'Location map',
                      'Mortgage calculator',
                      'Lead capture forms',
                      'Social media sharing',
                    ],
                  },
                ],
              },
              'Community pages using a pre-designed layout, limited to 30 to 40 communities only',
              {
                hasCheck: false,
                subItems: [
                  'Each page contains a section to add a community description, one (1) featured image, and a section to display active properties with an IDX provider as a data source for listings.',
                  'If a client wishes to display more than 40 communities, they will be given instruction on how to upload additional communities to the website.',
                ],
              },
              'Testimonials page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Manually uploaded, limited to 30 to 40 testimonials only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 40 testimonials, they will be given instruction on how to upload additional testimonials to the website.',
                      'Integration of third-party plugins and data sources, such as those from Zillow and Google reviews, is allowed, as long as access to these platforms is provided by the client.',
                      {
                        hasCheck: false,
                        subItems: [
                          'There may be a limit to the number of testimonials displayed on the website display, depending on the plugin chosen, which is beyond Agent Image’s control.',
                        ],
                      },
                    ],
                  },
                ],
              },
              'Social media page using a pre-designed layout',
              {
                hasCheck: false,
                subItems: [
                  'Client must register and validate their social media account credentials for respective social media channels in order to successfully integrate social media post content via the AIOS Social Media plugin. This may be discussed in detail with the website’s assigned Project Manager.',
                ],
              },
              'Contact page',
              {
                hasCheck: false,
                subItems: ['A pre-designed page with a lead capture form'],
              },
              'Buyers page with editable stock content using a pre-designed layout',
              'Sellers page with editable stock content using a pre-designed layout',
              'Real estate news feed from Inman News',
              {
                hasCheck: false,
                subItems: [
                  'May be replaced with a different RSS feed, if preferred',
                  'May be replaced with manually uploaded blog articles provided by the client, limited to 30 to 40 posts only',
                  {
                    hasCheck: false,
                    subItems: [
                      'If a client wishes to display more than 40 posts, they will be given instruction on how to upload additional posts to the website.',
                    ],
                  },
                ],
              },
            ],
          },
          'Client may request their Project Manager to add 15 additional pages to the website free of charge.',
          {
            hasCheck: false,
            subItems: [
              'These 15 pages will use the standard inner page layout. The header and footer will be patterned after the homepage design. The content section of these pages will allow for the addition of text and photos or display properties via IDX provider as a data source for listings.',
              'If a client wishes to display more than 15 additional pages, they will be given instruction on how to upload additional pages to the website.',
            ],
          },
        ],
      },
      '<strong>Available add-ons you can purchase with the Imagine Studio package</strong>',
      'Please note that purchase of any of these add-ons while the site build is in progress will extend the project timeline depending on the scope of work. If you are interested in any of these upgrades, a change order form will have to be completed.',
      {
        hasCheck: false,
        subItems: [
          '<strong>CRM Integration</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#crm-integration">(See CRM Integration)</a>',
            ],
          },
          '<strong>Custom Logo Design</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#custom-logo-design">(See Custom Logo Design)</a>',
            ],
          },
          '<strong>Custom Map</strong>',
          {
            hasCheck: false,
            subItems: [
              '<a class="scroll-smooth" href="#custom-map">(See Custom Map)</a>',
            ],
          },
          '<strong>Custom Property Layout</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
          '<strong>Custom Page Layout</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
          '<strong>Custom Form Layout and Functionality</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
          '<strong>Integration of any Third-Party Application/Plugin</strong>',
          {
            hasCheck: false,
            subItems: [
              'Price will need to be quoted depending on design and functionality.',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Client Approval',
    description: [
      'Client is responsible for testing and approving the functionality of all Services, including but not limited to websites and marketing services, upon TDP’s request and notification that the Services and/or website or marketing services have been completed. Client’s responsibility includes, but is not limited to, testing the functionality of all Services, website pages, databases, e-commerce stores, payment functions, galleries, forums, etc. Upon Client’s approval of the Services and/or website to go live, Client agrees and acknowledges that Services have been rendered by TDP and the functionality of the website has been tested and approved by Client.',
      'Client acknowledges and agrees that if Client does not respond within five (5) business days to TDP’s request for approval and notification that the Services and/or website have been completed, the Services and/or website, along with the functionality of the Services and/or website and services rendered, will be deemed to be approved by the Client, and the Services and/or website will be taken live “as-is”.',
      'Client acknowledges and agrees that if Client does not respond to requests by TDP for missing or incomplete information, a final notification and/or request will be sent to Client. If Client does not respond within five (5) business days to TDP’s notification or requests for missing information, the Services and/or website, along with the functionality of the Services and/or website and the services rendered, will be deemed to be approved by the Client, and the Services and/or website will be taken live with the missing or incomplete information “as-is” or “under construction”.',
    ],
  },
  {
    title: 'Warranty Period and Website and Online Marketing Maintenance',
    description: [
      'TDP agrees to provide Client with reasonable technical support and assistance to maintain and update the Services and/or website during a warranty period of sixty (60) days after publishing the Services and/or website on its final domain name (“Warranty Period”). Such technical support and assistance will be provided at no charge to Client for up to a total of two (2) hours. For technical support and assistance provided to Client by TDP beyond two (2) hours, Client agrees to pay TDP at its standard hourly rates ($150/hour). After the expiration of the Warranty Period, Client may enter into a new agreement with TDP for Services and/or website maintenance (“Maintenance Agreement”) wherein TDP agrees to provide reasonable technical support and assistance to Client to maintain and update the Services and/or website on the Internet for an agreed upon hourly fee. Any such fee will be due and payable when billed pursuant to the TOS. Such maintenance shall include correcting any errors or any failure of the Services and/or website to conform to the specifications and the development of enhancements to the originally contracted project. The Warranty Period shall not include the development of enhancements to the website expressly considered by Section 10 of the TOS.',
    ],
  },
  {
    title: 'Enhancements',
    description: [
      'If Client enters into a Maintenance Agreement with TDP, Client agrees that if Client wishes to modify and/or enhance the Services and/or website, Client will give TDP the option to submit a bid or proposal to perform such modifications and/or enhancements prior to accepting or soliciting any competing bids or proposals.',
    ],
  },
  {
    title: 'Technical Support',
    description: [
      'TDP’s technical support department can be contacted at <a href="mailto:support@thedesignpeople.com">Support@TheDesignPeople.com</a> for any concerns with Client email accounts or any problems with hosting or functionality of the Services and/or website after the Services and/or website are live.',
      'If Client uses TDP’s technical support services, including but not limited to screen share sessions, Client acknowledges and understands that TDP does not warranty that technical support services will meet Client’s requirements or be error free. <a class="scroll-smooth" href="#disclaimer-of-warranties">(See Disclaimer of Warranties and Limitation of Liabilities.)</a>',
    ],
  },
  {
    title: 'Customer Service',
    description: [
      'TDP strives to offer the best service available. Customer service complaints or concerns should be emailed to <a href="mailto:support@thedesignpeople.com">Support@TheDesignPeople.com</a>.',
    ],
  },
  {
    title: 'Use and Storage',
    description: [
      'Client acknowledges that TDP may establish general guidelines and limits concerning the use of TDP’s Services and may modify these guidelines at any time. Limits may include but are not restricted to, the maximum number of days that email messages or other content will be retained (90 days), maximum number of email messages that may be sent from or received by an account, the maximum size of any email messages sent and the maximum disk space that will be allotted on TDP’s servers on Client’s behalf. TDP periodically backs up the websites it hosts. However, Client acknowledges that TDP is not responsible for backing up Client’s website and data. Client should seek appropriate backup solutions. Changes made by Client using the editing tool or by TDP may be lost if data loss occurs after a scheduled backup by TDP.',
    ],
  },
  {
    title: 'Updates to Live Website/Design Time',
    description: [
      'Based on Client’s monthly website service package, TDP may provide 4 to 12 hours annually of free updates and changes or “design / development hours” that are available to Client to use after the website is live. After the website is live, Client may wish to make changes or updates to the website from time to time. Most changes can be made using TDP’s online editors. The online editors may not be available for all websites, or all pages of a website. Availability of the online editors is dependent upon the functionality and specifications required for the Client’s website. For changes that cannot be made with the editors, Client may use available TDP design hours. These changes must be requested through TDP’s Updates Department at <a href="mailto:support@thedesignpeople.com>Support@TheDesignPeople.com</a>. Design hours can be used for modifying the design or layout of the website. Design hours cannot be used toward the completion or modification of databases, custom programming, e-commerce or flash or other enhancements that must be purchased. Changes or updates that exceed the Client’s available “design hours” will be billed to the Client at the standard hourly rate.',
      'TDP is not responsible for any changes Client makes to his/her website, or if Client breaks the website. Time required by TDP to repair changes made by Client will be billed to Client at TDP’s standard hourly rate if it exceeds available “design / development hours”.',
    ],
  },
  {
    title:
      '<span class="id-placeholder" id="domain-names"></span>Domain Names Purchased/Hosting Agreement/Internet Marketing Services',
    description: [
      'Monthly hosting and Internet marketing services are billed every 30 days beginning from the date of sale. Monthly hosting and Internet marketing services are billed from the date of sale, regardless of the date the website goes live, because hosting and Internet marketing costs are incurred immediately. Server space is secured for the developing website and/or for existing Client domain names. In addition, email accounts and support are available from the date of sale and are paid for with monthly hosting fees. Client agrees to a one-year hosting commitment with TDP. Client also agrees to an initial Internet marketing commitment with TDP, although the durations of the Internet marketing commitments may vary. At the conclusion of the initial one-year hosting commitment, and the initial commitment for Internet marketing services, Client’s commitment will continue on a month-to-month basis and Client will continue to be billed every 30 days.',
      'Domain names purchased by TDP and website designs, databases, stores, or programs created by TDP are the property of TDP until Client has paid all fees including one full year of monthly hosting. At that time ownership of the site and its functionality, and domain name may be transferred to the Client’s control upon receipt of the Client’s written request. Sham purchases of sites may not be transacted with the intent and/or result of having a site built, then transferring the site to another hosting provider. Clients may buy-out their hosting by paying twelve (12) months of hosting in advance if they wish to transfer the domain name and content to Client’s control prior to one year of paid monthly hosting.',
      'Upon transfer of domain and/or website to Client or another service provider, at anytime, Client agrees that TDP has met in full its obligation to Client, and TDP is released of all past and future obligations to the Client. Additional work and/or hosting done for Client must be agreed to in writing and paid for by Client.',
      'Domain names are purchased through a third party service. TDP cannot guarantee the availability of domain names and has no liability for a domain name not being available for purchase after the initial sale. If a domain name is not available for purchase, TDP will assist the Client in selecting and purchasing an alternate domain name. Domain names already owned by the Client remain the property of the Client and renewal of the domain name is the Client’s responsibility. The renewal of any domain name transferred to the Client is the responsibility of the Client. In the event a domain name initially was purchased by TDP for or on behalf of a Client, Client is solely responsible for any renewal of the domain name and TDP shall not be responsible or liable for the expiration of any domain name, however selected or purchased. Service and technical support for domain names are provided by Wild West Domains, Inc. (www.WildWestDomains.com), an entity wholly separate and distinct from TDP. Service and technical support for domain names can be accessed at domains.agentimage.com.',
    ],
  },
  {
    title: 'Internet Access',
    description: [
      'Access to the Internet will be provided to Client by a separate, third party Internet Service Provider (ISP). Client is solely responsible for entering into and maintaining any contract for service with an ISP. The ISP is not a party to this agreement.',
    ],
  },
  {
    title: 'Marketing Representations',
    description: [
      'TDP makes no representations as to the marketing of Client’s products, services or sales. Client’s obligation to pay fees due to TDP are due at time of sale of website design, marketing and hosting services and are not contingent upon Client’s marketing of said website. Client is responsible for all marketing of Client’s website.',
    ],
  },
  {
    title: 'Billing Policy',
    description: [
      'All prices quoted in a Client Agreement or Purchase Order are subject to this Agreement. Unless otherwise stated in writing by TDP, all prices quoted in a Client Agreement or Purchase Order are exclusive of transportation, insurance, and federal, state, local, excise, value-added, use, sales, property (ad valorem) and similar taxes or duties now in force or hereafter in effect, as applicable. In addition to the prices quoted in a Client Agreement or Purchase Order or invoiced, Client agrees to pay all taxes, fees, or charges of any nature whatsoever imposed by any governmental authority on, or measured by, the transaction between Client and TDP. In the event that TDP is required to collect such taxes, fees, or charges, such amounts will appear as separate items on TDP’s invoice and will be paid by Client.',
      'The initial Services set-up fee is due and billed in-full at the time of the original sale. (Installment payments may be accepted for the set-up fee only if agreed to in writing pursuant to the original invoice.) Monthly hosting and other Services plans, including but not limited to Internet marketing services, are billed and due upon transmission of the invoice. The Client hereby authorizes TDP to renew and bill monthly hosting and Internet marketing service fees every 30 days, unless the Client cancels in writing after the initial hosting and/or Internet marketing services commitments have been paid.',
      'The amount of the initial Services set-up fee and monthly hosting fees are detailed on the original invoice confirming amounts of sale. Monthly hosting and Internet marketing services amounts may vary depending upon the hosting package selected by Client.',
      'TDP reserves the right to change prices at any time including monthly hosting and Internet marketing service amounts and hourly design fees.',
      'TDP accepts payment via wire transfer, check, credit card and EFT debit from Client’s bank account. Upon Client’s authorization of payment to TDP via credit card, or EFT debit payment, Client thereby authorizes all recurring monthly hosting and/or additional Services fees, including but not limited to Internet marketing services, to be charged to the same method of payment, credit card or EFT account for future charges until such authorization is withdrawn by Client in writing.',
      'The Client may change payment methods including credit card and EFT debit payments with 30 days’ notice. To change payment methods Client shall contact TDP’s billing department. Client should not email new billing information for security purposes.',
    ],
  },
  {
    title:
      '<span class="id-placeholder" id="billing-disputes"></span>Billing Disputes',
    description: [
      'TDP charges up to a $25.00 fee for returned checks and a $200.00 fee to handle unauthorized credit card disputes. If TDP does not receive payment in full when due, TDP may, to the extent permitted by the law of the state of the billing address on file for Client at the time, charge a late fee of up to 1.5% per month (18% per annum), or a flat fee of $5 per month, whichever is greater, on any unpaid balance. TDP may, to the extent permitted by the law of the state of the billing address on file for Client at the time account is sent to a collection agency, also charge Client for any collection agency fees and/or attorney’s fees billed to TDP for collecting unpaid amounts due and owing from Client. TDP will not honor limiting notations made by a Client on a check.',
      'If Client wishes to dispute a charge, Client must first contact TDP’s billing department and must allow ten (10) business days for a response. To avoid any dispute about Client’s attempt to contact TDP, Client must send the request in writing to:',
      'Attn: Billing Department, The Design People, Inc., 1700 E. Walnut Avenue, Suite 240, El Segundo, California 90245. Requests may be emailed to <a href="mailto:billing@thedesignpeople.com">Billing@TheDesignPeople.com</a>. If Client chooses to send request by email, a copy of the request must also be sent by mail as confirmation. If Client initiates a credit card dispute the decision of the credit card company is made through an arbitration process and the decision of the credit card company shall be binding upon Client. <a class="scroll-smooth" href="#arbitration">(See Arbitration)</a>',
    ],
  },
  {
    title:
      '<span class="id-placeholder" id="termination"></span>Termination/Cancellation of Services',
    description: [
      'TDP, at its sole discretion, may terminate its Services and remove and discard any content, for any reason, including and without limitation, for lack of use, or if TDP believes Client has violated the TOS. TDP may also, at its sole discretion and at any time, discontinue providing Services, or any part thereof, with or without notice. Client agrees that any termination of access to TDP’s Services under any provision of this TOS may be effected without prior notice and that TDP may deactivate or delete Client’s account and all related information files. Client agrees that TDP shall not be liable to Client or any third-party for any termination of Services. Paid accounts that are terminated will not be refunded. In addition, accounts that become 30-days delinquent may be terminated. TDP also reserves the right to discontinue the designing of Client’s website at any time, at TDP’s sole discretion, with an appropriate refund to the Client. Under no circumstances is the refunded amount to exceed the amount collected by TDP.',
      'If the Client cancels a Service or Services before the work is completed or the Services and/or website are live, a cancellation fee is retained per the Cancellation Fee and Refund Policy. Client agrees that all fees incurred and billed prior to the effective date of cancellation are valid and Client agrees to pay them. Upon request for cancellation of Services, the Services will be cancelled, including, but not limited to, the removal of any websites. A back-up copy of the website is not maintained by TDP.',
      'If Client elects to cancel a monthly website contract prior to the conclusion of a specified term of months (e.g., a 24-month term), Client acknowledges that it will be charged and must pay for the monthly website contract through the end of its term. (For example, if Client enters into a 24-month website contract, and Client cancels the contract at the conclusion of the 11th month, Client will owe and agrees to pay TDP for the remaining 13-months of the contract.) In the event Client elects to cancel a monthly website contract prior to the end of specified term of months, Client agrees and acknowledges that it does not own the website, and Client may not take the website upon cancellation.',
      'Client agrees to pay all hosting fees and additional services fees, including, but not limited to, Internet marketing services, owed from the time of sale until the effective date of cancellation, and at a minimum for hosting fees for one year. Transferring a domain name to another provider or non-use of Client’s hosting account does not constitute termination of the account. Client must notify TDP in writing or via email to terminate the Services and avoid further monthly hosting charges. It is Client’s responsibility to secure confirmation from TDP that the request for termination has been received and no further hosting fees will be billed.',
      'Requests for cancellation of Services should be sent to the following address: Attn: Billing Department, The Design People, Inc., 1700 E. Walnut Avenue, Suite 240, El Segundo, California 90245.',
      'Requests may be emailed to <a href="mailto:billing@thedesignpeople.com">Billing@TheDesignPeople.com</a>. If Client chooses to send a request by email, a copy of the request must also be sent by mail as confirmation.',
    ],
  },
  {
    title: 'Cancellation Effective Date',
    description: [
      'Client may cancel Services with 30-days’ written notice. The effective date of cancellation will be 30-days from the date of TDP’s receipt of written notice to cancel Services. Any monthly fees scheduled to bill after receipt of written notice to cancel, but before the effective date of cancellation, are valid and Client agrees to pay such amounts. Client is required to pay TDP for one-year of hosting Services. This requirement must be satisfied even if Client elects to cancel Services within the first year of a Client Agreement or Purchase Order. Client’s cancellation of Services does not release Client from its obligation to pay TDP for one-year of hosting Services. Also, as previously stated in Section 29, if Client elects to cancel a monthly website contract prior to the conclusion of a specified term of months (e.g., a 24-month term), Client acknowledges that it will be charged and must pay for the monthly website contract through the end of its term. (For example, if Client enters into a 24-month website contract, and Client cancels the contract at the conclusion of the 11th month, Client will owe and agrees to pay TDP for the remaining 13-months of the contract.) In the event Client elects to cancel a monthly website contract prior to the end of specified term of months, Client agrees and acknowledges that it does not own the website, and Client may not take the website upon cancellation.',
      'If Client has not paid all fees due for Services, such fees are due in full at the time of cancellation and Client authorizes TDP to collect any outstanding fees due, subject to the Cancellation Fee and Refund Policy. Client acknowledges and agrees that any and all pending billing for design fee installments previously agreed to will not be cancelled.',
    ],
  },
  {
    title: 'Default, Cancellation and Refund Policy',
    description: [
      'In the event that Client is in breach or default in payment for the Services, Client shall be responsible for all reasonable costs and expenses incurred by Client in collection of any sums owing by Client (including reasonable attorneys’ fees), and TDP may decline to render further Services to Client without in any way affecting its rights under this Agreement. If, despite any such breach or default by Client, TDP elects to continue to render the Services, rendering the Services shall not constitute a waiver of any breach or default by Client or in any way affect TDP’s legal remedies arising from such breach or default.',
      'No refund shall be given to the Client for cancellation “for convenience,” which is made three (3) days after payment. Notwithstanding this, the Client may treat all advanced and processed payments to TDP as credits for future services and projects. TDP shall furnish the Client with its latest billing details upon cancellation to reflect the remaining balance usable by the Client for future services.',
      'For avoidance of doubt, any cancellation that is made based on causes other than TDP’s violation of any of the terms and conditions of its engagement with Client shall be treated as a cancellation for convenience.',
      '<strong>MINIMUM CANCELLATION FEE</strong> – Client agrees that a minimum cancellation fee of 25% will be retained by TDP on all cancelled accounts even if no work has been started. The cancellation fee is charged to compensate TDP for upfront expenses and services rendered, including but not limited to, costs incurred for the purchase of domain name(s) for developing the website, securing server space, creating the temporary website or space saver, employee expenses, marketing, and overhead costs.',
      '<strong>ENHANCEMENT SALES</strong> – A 100% cancellation fee will be retained by TDP and NO REFUND will be issued once work has begun on any enhancements purchased, including, but not limited to, databases, programming, logos, galleries, rollovers, e-commerce stores, etc. Client agrees that a minimum cancellation fee of 50% will be retained by TDP on all cancelled enhancement purchases if cancelled within 60-days of the enhancement sale and if work has not yet begun. NO REFUND will be issued by TDP if Client cancels after 60-days from the enhancement sale. Enhancements or additional services purchased after the initial sale are separate purchases and are in addition to and separate from the original sale. If a Client cancels an enhancement, the original sale is not cancelled.',
      '<strong>MONTHLY HOSTING</strong> – Client agrees that there is NO REFUND of monthly hosting fees or monthly additional services fees, including but not limited to Internet marketing services, incurred or paid by Client prior to cancellation date.',
      'Client agrees that all fees incurred and billed prior to cancellation date are valid and Client agrees to pay.',
    ],
  },
  {
    title: 'Copyright Protection',
    description: [
      'Client is advised to copyright and protect all final art against duplication or alteration. TDP is not responsible for any Client’s copyrightable material.',
    ],
  },
  {
    title: 'The Design People, Inc.’s Proprietary Rights',
    description: [
      'Client acknowledges and agrees that TDP’s Services may contain proprietary and confidential information that is protected by intellectual- and proprietary-rights laws. Client agrees to not reproduce, duplicate, copy, sell, resell or exploit any portion of TDP’s Services or proprietary or confidential information. TDP does not provide direct access, through File Transfer Protocol or other means, to any proprietary technology.',
    ],
  },
  {
    title: 'Use of Client Information',
    description: [
      'Client hereby agrees that any information or ideas submitted to TDP by any means may be used by TDP without compensation or liability to Client for any purpose whatsoever, including but not limited to, developing websites, databases, e-commerce and developing, manufacturing and marketing other products. This provision does not apply to Client content or to personal information that is subject to TDP’s Privacy Policy.',
      'Client hereby gives permission to TDP to use samples or links to Client’s custom website designed by TDP for marketing and advertising purposes, including but not limited to, use in TDP’s online portfolio.',
    ],
  },
  {
    title: 'Third-Party Services',
    description: [
      'From time to time third parties may offer services to TDP’s clients. Use of such third-party services will be at Client’s own risk and subject to the terms and conditions of those third parties. Third-party services, including but not limited to, IDX, VOW, and other MLS services, are the responsibility of and shall be paid by Client to its chosen provider. TDP does not represent or warrant that use or access to any third-party services will be compatible, uninterrupted, error free, without defects or that Client will be able to access TDP’s services. Client also agrees that TDP is under no obligation to provide Client with any enhancements, updates, or fixes to make TDP’s services accessible through any third-party applications.',
    ],
  },
  {
    title: 'Contract Service Providers',
    description: [
      'TDP may contract with Contract Service Providers to complete a portion, or all, of the Client’s custom website and/or Services. The Client agrees not to do business directly with the Contract Service Provider, nor to remit payment to the Contract Service Provider or any TDP employee directly for services. All payments for services rendered must be made directly to TDP. Contract Service Providers are required to enter into employment contracts and to follow company policies and procedures. Contract Service providers are provided with only the information needed to complete the design or development portion of the Client’s Services and/or website and do not have access to Client’s personal information including payment information.',
    ],
  },
  {
    title:
      '<span class="id-placeholder" id="disclaimer-of-warranties"></span>Disclaimer of Warranties',
    description: [
      'CLIENT’S USE OF TDP’S SERVICES IS AT CLIENT’S OWN RISK. TDP’S SERVICES ARE PROVIDED “AS IS”. TDP DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. TDP DISCLAIMS ANY WARRANTIES REGARDING TDP’S SERVICES INCLUDING THAT THEY WILL MEET CLIENT’S REQUIREMENTS, THAT THEY WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. TDP DISCLAIMS ANY WARRANTIES REGARDING THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF TDP’S SERVICES, INCLUDING RESULTING SALES AND WEB TRAFFIC. TDP DISCLAIMS ANY WARRANTIES REGARDING THE MARKETING OF CLIENT’S PRODUCTS, SERVICES, SALES, OR WEBSITE. TDP DISCLAIMS ANY WARRANTIES REGARDING THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED, ADVERTISED OR OBTAINED THROUGH TDP’S SERVICES, OR LINKS PROVIDED BY TDP’S SERVICES, AS WELL AS FOR ANY INFORMATION OR ADVICE PROVIDED BY TDP OR OBTAINED THROUGH LINKS PROVIDED THROUGH TDP’S SERVICES.',
      'CLIENT UNDERSTANDS AND AGREES THAT ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF TDP’S SERVICES ARE DONE AT CLIENT’S OWN RISK AND THAT CLIENT WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO CLIENT’S COMPUTER SYSTEM OR LOSS OF DATA OR OTHER LIABILITY THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES. THE ABOVE EXCLUSIONS MAY NOT APPLY TO CLIENT.',
    ],
  },
  {
    title: 'Limitation of Liability',
    description: [
      'CLIENT UNDERSTANDS AND AGREES THAT TDP, ITS SUBSIDIARIES, AFFILIATES, OFFICERS, AND EMPLOYEES SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, (EVEN IF TDP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). SUCH LIMITATION OF LIABILITY SHALL APPLY WHETHER THE DAMAGES ARISE FROM THE USE OF OR INABILITY TO USE TDP’S SERVICES, RELIANCE ON TDP’S SERVICES, OR FROM THE INTERRUPTION, SUSPENSION, OR TERMINATION OF TDP’S SERVICES (INCLUDING SUCH DAMAGES INCURRED BY THIRD PARTIES.) THIS LIMITATION SHALL ALSO APPLY, WITHOUT LIMITATION, TO THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES RESULTING FROM PRODUCTS OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED THROUGH TDP’S SERVICES OR FOR UNAUTHORIZED ACCESS TO OR ALTERATION OF CLIENT’S DATA OR TRANSMISSIONS AND ANY STATEMENTS OR CONDUCT OF A THIRD PARTY OR ANY OTHER MATTERS RELATING TO TDP’S SERVICES. SUCH LIMITATION SHALL FURTHER APPLY, WITH RESPECT TO THE PERFORMANCE OR NON-PERFORMANCE OF SERVICES OR ANY INFORMATION OR MERCHANDISE THAT APPEARS ON, OR IS LINKED IN ANY WAY TO TDP’S SERVICES.',
      'SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO CLIENT.',
      'Without limiting the foregoing, under no circumstance shall TDP be liable for any delay or failure in performance resulting directly or indirectly from acts of nature, forces or causes beyond its reasonable control, including without limitation, internet failures, computer equipment failures, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, or other casualties, illness, accidents, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations in heat, light or air conditioning. TDP’s full and complete liability, for any reason whatsoever, shall be limited to the full refund of all monies paid to TDP.',
    ],
  },
  {
    title: 'Tort Claims',
    description: [
      'Client waives all tort claims against TDP, its subsidiaries, affiliates, officers, employees and agents. The relationship between the parties is contractual in nature only. Client waives any tort claims that arise by act, or omission.',
    ],
  },
  {
    title: 'Indemnification',
    description: [
      'Client agrees to defend, indemnify and hold harmless TDP, its directors, officers, employees and agents from and against all claims and expenses, including attorneys’ fees that may arise or result from any content Client submits, posts, transmits or makes available through TDP’s Services, from any product sold by Client, its agents or employees or assigns, from any Services provided or performed or agreed to be performed by TDP or from Client’s violation of the TOS or Client’s violation of any rights of another, whether such rights arise by statute, common law, contract, or otherwise. Client further agrees to defend, indemnify and hold harmless TDP, its directors, officers, employees and agents from and against all claims and expenses, including attorneys’ fees, arising from or related to contracts, representations, agreements, promises, etc., made between Client and third parties, or arising from or related to Client’s negligence toward third parties.',
    ],
  },
  {
    title: 'Other Operating Systems Conversions',
    description: [
      'TDP shall be given first option at compiling the work for operating systems beyond the original use.',
    ],
  },
  {
    title: 'Unauthorized Use and Program Licenses',
    description: [
      'Client will indemnify TDP against all claims and expenses arising from uses for which Client does not have rights to or authority to use. Client is and will be responsible for payment of any special licensing or royalty fees resulting from the use of graphics programs that require such payments.',
    ],
  },
  {
    title: 'Notice',
    description: [
      'Unless otherwise specifically provided, all notices required or permitted by the TOS shall be in writing and in English and may be delivered personally, or may be sent by email, facsimile or certified mail, return receipt requested, to the address set forth below. If Client chooses to send request by email or facsimile, a copy of the request must also be sent by mail (to the address below) as confirmation of the request.',
      'The Design People, Inc.<br />1700 E. Walnut Avenue, Suite 400<br />El Segundo, California 90245',
    ],
  },
  {
    title: 'Severability',
    description: [
      'In the event that any provision hereof is found invalid or unenforceable pursuant to judicial decree or decision, the remainder of the TOS shall remain valid and enforceable according to its terms.',
    ],
  },
  {
    title: 'Jurisdiction',
    description: [
      'The TOS agreement shall be governed by the laws of the State of California. All legal proceedings are to be submitted exclusively to the jurisdiction of the courts in the State of California, County of Los Angeles.',
    ],
  },
  {
    title: '<span class="id-placeholder" id="arbitration"></span>Arbitration',
    description: [
      'In lieu of litigation, Client agrees to binding arbitration if requested by TDP. Arbitration will be through the Better Business Bureau or other neutral third-party Arbitrator to be selected by TDP. If Client initiates a credit card dispute the decision of the credit card company is made through an arbitration process and the decision of the credit card company shall be binding upon Client. <a class="scroll-smooth" href="#billing-disputes">(See Billing Disputes)</a>',
    ],
  },
  {
    title: 'Governance',
    description: [
      'TDP may investigate any reported violations of this agreement, its policies or any other complaints and take any action it deems appropriate to protect its systems, facilities, Clients, and/or third parties.',
    ],
  },
  {
    title: 'Electronic Signatures',
    description: [
      'Selecting and submitting “accept” on the electronic copy of the TOS, submitting content through the DMP, making payment, or submitting information or documents to TDP so that TDP may perform services for the Client, the same shall constitute an electronic signature.',
    ],
  },
  {
    title: 'General Information',
    description: [
      'This Agreement constitutes the entire understanding and contract between the parties and supersedes any and all prior oral or written agreements (including, but not limited to, any prior versions of the TOS). Any modifications to this agreement must be in writing and signed by an authorized officer of TDP. All representations not in writing are null and void. Written agreements may include, but are not limited to, emails and electronic acceptance of this TOS.',
    ],
  },
  {
    title: 'CCPA Data Processing Addendum',
    description: [
      'This CCPA Data Processing Addendum (the “Addendum”) reflects the requirements of the California Consumer Privacy Act of 2018 (“CCPA”), as amended (Cal. Civ. Code § 1798.100, et seq.), and any related regulations or guidance provided by the California Attorney General. Any terms defined in the CCPA, including personal information and business purpose, carry the same meaning in this Addendum. This Addendum applies, and forms part of the Terms of Service between TDP and Client, if and to the extent Client is a business under the CCPA. This Addendum prevails over any conflicting terms in the Terms of Service.',
      'TDP and Client agree that, for purposes of the CCPA, TDP is a service provider to Client. This Addendum governs the retention, use, or disclosure of personal information that is processed by TDP on Client’s behalf or that Client otherwise discloses to TDP for a business purpose. TDP will not retain, use, or disclose any personal information processed on Client’s behalf or that is otherwise disclosed to TDP for a business purpose, for any purpose other than to perform the services for Client as specified in the Terms of Service and in any Client Agreement and/or Purchase Order, or as otherwise permitted by the CCPA.',
      'Client represents and warrants that it will comply with all requirements applicable to businesses under the CCPA when collecting, using, retaining, or disclosing personal information. For example, Client will provide notice to consumers that personal information is being used by or shared with a service provider, consistent with Cal. Civ. Code § 1798.140(t)(2)(C)(i). Client will also provide any other notices required by the CCPA regarding the collection, use, retention, or disclosure of personal information, in the form and manner required by the CCPA, including with respect to any personal information collected by TDP on Client’s behalf.',
      'TDP will provide reasonable cooperation and assistance to Client so that Client can meet its compliance obligations under the CCPA, including responding to verifiable consumer requests, taking into account the information processed by and available to TDP. TDP will promptly notify Client of any complaint, notice, or communication TDP receives from a consumer relating to Client’s compliance obligations under the CCPA.',
    ],
  },
]
